<template>
  <v-card class="glow" :loading="loading">
    <v-img height="200" src="../../assets/card-bg-only-racer.jpg">
      <v-card-title class="white--text">
        <h3 class="pa-2">{{ title }}</h3>
      </v-card-title>
      <p class="pl-10 pr-10 white--text">
        {{ instruction }}
      </p>
    </v-img>

    <v-card-text class="pl-10 pr-10">
      <ApiError :errors="errors"></ApiError>

      <div v-if="loadingEnroll" class="text-center">
        <v-progress-circular indeterminate></v-progress-circular>
        <h3 class="mt-5">Processing...</h3>
        <p>Please do not close this window or refresh the page.</p>

        <h4 class="mb-4">
          We are currently processing your enrollment and creating your reloable card ...
        </h4>
      </div>

      <div v-else-if="step === 'PROFILE'">
        <RacerProfile
          v-if="!loading"
          :fromRegister="fromRegister"
          :form="form"
          v-model="valid.step1"
          :rules="rules"
          @next="completeProfile()"
        />
      </div>

      <div v-else-if="step === 'VEHICLE_MANAGEMENT'">
        <VinTableManager :value.sync="vehicles" :loading="loading"></VinTableManager>
        <v-container class="mt-5">
          <v-row>
            <v-col>
              <v-btn :loading="loading" @click="changeStep('PROFILE')">
                <v-icon left>mdi-arrow-left</v-icon> Back
              </v-btn>
            </v-col>
            <v-col class="text-right">
              <v-btn :disabled="!validVehiclesForm" color="primary" @click="changeStep('AGREEMENT')">
                Next <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div v-else-if="step === 'AGREEMENT'">
        <RacerSiteAgreement
          :loading="loading"
          @previous="changeStep('VEHICLE_MANAGEMENT')"
          @next="handleAgreementAccepted"
        />
      </div>

      <div v-else-if="step === 'COMPLETE'" class="text-center">
        <v-icon x-large>mdi-flag-checkered</v-icon>

        <h3 class="mb-4">Your Registration has been Submitted!</h3>

        <h4 class="mb-4">
          Thank you for joining the Racer Rewards program. We're excited to have you onboard!
        </h4>

        <p>If you have any questions or need further assistance, please feel free to contact our support team.</p>

        <v-btn class="my-8" :loading="loading" color="primary" @click="onFinished">
          <!-- finished -->
          Continue
        </v-btn>
      </div>

      <div v-else-if="step === 'CARD_ERROR'" class="text-center">
        <v-icon x-large color="red">mdi-alert-circle</v-icon>

        <h3 class="mb-4">Account Created Successfully</h3>

        <h4 class="mb-4">
          However, we encountered an error while issuing your card.
        </h4>

        <p>
          Please contact Kawasaki support for assistance. They will help resolve the issue and ensure your card is
          issued promptly.
        </p>

        <v-btn class="my-8" color="primary" @click="onFinished">
          Continue
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import ApiService from "@/gapp-components/services/api.service";
import ValidationService from "@/gapp-components/services/validation.service";
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import ApiError from "../../gapp-components/components/display/ApiError.vue";
import VinTableManager from "../common/table/VinTableManager.vue";
import RacerProfile from "./RacerProfile.vue";
import RacerSiteAgreement from "./RacerSiteAgreement.vue";

export default {
  components: {
    RacerProfile,
    RacerSiteAgreement,
    ApiError,
    VinTableManager
  },
  data() {
    return {
      step: "PROFILE",
      errors: {},
      valid: {
        step1: false,
        step2: false,
        step3: false
      },
      form: {
        address: {},
        riderType: [],
        customFieldValue4: "",
        customFieldValue5: "",
        customFieldValue7: "",
        customFieldValue11: "",
        customFieldValue12: "",
        transientTin: ""
      },
      loading: false,
      loadingEnroll: false,
      vehicles: {
        existing: [],
        delete: [],
        isValid: false
      },
      fromRegister: false
    };
  },

  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram"]),
    rules() {
      return {
        address1: [
          ValidationService.generateNotWhitespacesRule("Address 1"),
          ...ValidationService.generateAddressValidationRules("Address 1")
        ],
        address2: [
          ValidationService.generateNotWhitespacesRule("Address 2"),
          ...ValidationService.generateAddressValidationRules("Address 2", 200, false)
        ],
        city: [
          ValidationService.generateNotWhitespacesRule("City"),
          ...ValidationService.generateTextValidationRules("City", 100, true)
        ],
        region: ValidationService.generateDropdownValidationRules("Region"),
        postalCode: ValidationService.generateTextValidationRules("Postal Code"),
        phoneNumber: ValidationService.generateTextValidationRules("Phone Number"),
        tin: [...ValidationService.generateTextValidationRules("Social Security Number")],
        customFieldValue7: ValidationService.generateDropdownValidationRules("Rider Status"),
        customFieldValue12: ValidationService.generateTextValidationRules("AMA Licence Number", 10),
        customFieldValue11: ValidationService.generateCheckboxValidationRules("Rider Type")
      };
    },
    validVehiclesForm() {
      return this.vehicles.existing?.length > 0 || this.vehicles.isValid;
    },
    title() {
      if (this.loadingEnroll) {
        return "Kawasaki Racer Rewards - Enrolling ...";
      } else if (this.step === "PROFILE") {
        return "Kawasaki Racer Rewards - Rider Profile";
      } else if (this.step === "VEHICLE_MANAGEMENT") {
        return "Kawasaki Racer Rewards - Add Vehicles";
      } else if (this.step === "AGREEMENT") {
        return "Kawasaki Racer Rewards - Site Agreement";
      } else if (this.step === "COMPLETE") {
        return "Kawasaki Racer Rewards";
      } else {
        return "";
      }
    },
    instruction() {
      if (this.loadingEnroll) {
        return "We are working to enroll your account ...";
      } else if (this.step === "PROFILE") {
        if (this.fromRegister) {
          return "Please fill out the following rider information to join the program.";
        } else {
          return "Please review and fill out the following rider information to join the program.";
        }
      } else if (this.step === "VEHICLE_MANAGEMENT") {
        return "Please add at least one vehicle to your profile to continue.";
      } else if (this.step === "AGREEMENT") {
        return "Please review and accept the following Kawasaki Site Agreement.";
      } else if (this.step === "COMPLETE") {
        return "Your submission to join the Racer Rewards program has been successfully submitted!";
      } else {
        return "";
      }
    }
  },
  mounted() {
    this.form.address = this.selectedParticipant.address;
    this.form.phoneNumber1 = this.selectedParticipant.phoneNumber1;
    this.fromRegister = this.$route.query.register == "1";
    this.fetchData();
  },
  methods: {
    changeStep(s) {
      this.step = s;
      window.scrollTo(0, 0);
    },
    completeProfile() {
      if (this.$kawasaki.isRacer()) {
        this.changeStep("VEHICLE_MANAGEMENT");
      } else {
        this.changeStep("AGREEMENT");
      }
    },
    fetchData() {
      this.changeStep("PROFILE");
      this.loading = true;
      this.errors = {};
      return ApiService.get("/api/participants/" + this.selectedParticipant.id)
        .then(({ data }) => {
          this.form = data;
          this.form.customFieldValue11 = this.form.customFieldValue11 ? this.form.customFieldValue11.split("|") : [];
        })
        .catch(error => {
          this.errors = ApiService.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleAgreementAccepted() {
      this.onSubmit();
    },
    async onSubmit() {
      this.errors = {};
      this.joinParticipantOnRacerProgram();
    },
    async joinParticipantOnRacerProgram() {
      this.errors = {};
      this.loading = true;
      this.loadingEnroll = true;
      try {
        if (this.$kawasaki.isRacer()) {
          await this.updateParticipant();
          await this.finish();
        } else {
          await this.enrollParticipant();
          await this.finish();
        }

        try {
          await this.$kawasaki.createReloadableCard("RACER_PAYOUTS");
          this.changeStep("COMPLETE");
        } catch (cardError) {
          this.changeStep("CARD_ERROR");
          console.error("Error issuing card:", cardError);
        }
      } catch (error) {
        console.log(error);
        this.errors = this.$api.getErrorsFromResponse(error);
      } finally {
        this.loading = false;
        this.loadingEnroll = false;
      }
    },

    enrollParticipant() {
      let postForm = {
        participant: {
          status: { name: "ENR" },
          participantType: { participantTypeKey: "400" },
          email1: this.selectedParticipant.email1,
          user: {
            username: this.selectedParticipant.user.username,
            email: this.selectedParticipant.user.email
          },
          address: this.selectedParticipant.address,
          customFieldValue1: moment().format("YYYY-MM-DD"),
          customFieldValue2: "true",
          termsAndConditionsAccepted: true,
          customFieldValue7: this.form?.customFieldValue7,
          customFieldValue10: this.form?.customFieldValue10,
          customFieldValue11: this.form?.customFieldValue11?.join("|"),
          customFieldValue12: this.form?.customFieldValue12,
          customFieldValue13: this.form?.customFieldValue13,
          customFieldValue14: this.form?.customFieldValue14,
          customFieldValue15: this.form?.customFieldValue15
        },
        emailTemplateParticipantForm: { emailTemplateKey: "RACER_REWARDS_JOIN" }
      };

      if (!this.fromRegister) {
        postForm.participant.address = {
          address1: this.form.address.address1,
          address2: this.form.address.address2,
          city: this.form.address.city,
          postalCode: this.form.address.postalCode,
          region: this.form.address.region,
          country: this.selectedParticipant.address.country
        };
        postForm.participant.phoneNumber1 = this.form.phoneNumber1;
      }

      return this.$api.post("/api/participants/enroll", postForm).then(({ data }) => {
        return this.$auth.storeAvailableParticipants().then(() => {
          return this.$auth.storeSelectParticipant(data).then(() => {
            // If User already exists, a TIN is not updated during enroll, so updated it here
            if (this.form.transientTin) {
              return this.$api.post("/api/users/setTin", { tin: this.form.transientTin });
            } else {
              return Promise.resolve();
            }
          });
        });
      });
    },

    async updateParticipant() {
      // Format multi-value field
      this.form.customFieldValue11 = this.form?.customFieldValue11?.join("|");

      // Joining racer program
      this.form.customFieldValue1 = moment().format("YYYY-MM-DD");
      this.form.customFieldValue2 = "true";
      this.form.termsAndConditionsAccepted = true;

      const { data } = await this.$api.patch("/api/participants/" + this.selectedParticipant.id, this.form);
      if (this.form.transientTin) {
        await this.$api.post("/api/users/setTin", { tin: this.form.transientTin });
      }

      this.form = data;

      let emailTemplateForm = {
        emailTemplateKey: "RACER_REWARDS_JOIN",
        participant: { id: this.selectedParticipant.id }
      };

      try {
        await this.$api.post("/api/emailTemplates/sendToParticipant", emailTemplateForm);
      } catch (emailError) {
        console.error("Error sending racer join email");
      }
    },

    async finish() {
      this.loading = true;
      try {
        const { data } = await this.$api.get("/api/participants/bySelectedParticipant");
        await this.$auth.storeSelectParticipant(data);
        await this.$auth.storeAvailableParticipants();
      } catch (error) {
        console.error("Error during participant update:", error);
      } finally {
        this.loading = false;
      }
    },

    onFinished() {
      this.selectedParticipant.customFieldValue4 = this.form.customFieldValue4;
      this.selectedParticipant.customFieldValue5 = this.form.customFieldValue5;

      this.selectedParticipant.customFieldValue11 = this.form.customFieldValue11;

      return this.$auth.storeSelectParticipant(this.selectedParticipant).then(() => {
        return this.$router.push({ name: "dashboard" });
      });
    }
  }
};
</script>
