<template>
  <v-form ref="form" v-model="valid" class="mb-5">
    <v-container>
      <span v-if="!fromRegister">
        <h3 class="pt-5 pb-5">Please confirm your Address and Phone Number</h3>
        <p class="pb-5">
          Your Kawasaki prepaid card will be mailed to your address. Please verify that we have your address and phone
          number correct.
        </p>
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
            <v-text-field
              dense
              name="address1"
              :label="$i18n.translate('Address Line') + ' ' + 1"
              v-model="form.address.address1"
              :rules="rules.address1"
              :error-messages="$error.getValidationError(errors, 'address1')"
              @input="$error.clearValidationError(errors, 'address1')"
              class="required"
              outlined
            />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
            <v-text-field
              id="address2"
              name="address2"
              :label="$i18n.translate('Address Line') + ' ' + 2"
              v-model="form.address.address2"
              :rules="rules.address2"
              :error-messages="$error.getValidationError(errors, 'address2')"
              @input="$error.clearValidationError(errors, 'address2')"
              dense
              outlined
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="py-0">
            <v-text-field
              id="city"
              name="city"
              :label="$i18n.translate('City')"
              v-model="form.address.city"
              :rules="rules.city"
              :error-messages="$error.getValidationError(errors, 'city')"
              @input="$error.clearValidationError(errors, 'city')"
              class="required"
              dense
              outlined
            />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="py-0">
            <RegionField
              id="region"
              name="region"
              :label="$i18n.translate('Region')"
              :country="form.address.country"
              v-model="form.address.region"
              :rules="rules.region"
              :error-messages="$error.getValidationError(errors, 'region')"
              @input="$error.clearValidationError(errors, 'region')"
              class="required"
              dense
              outlined
            />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="py-0">
            <PostalCodeField
              id="postalCode"
              name="postalCode"
              :label="$i18n.translate('Postal Code')"
              :country="form.address.country"
              v-model="form.address.postalCode"
              :rules="rules.postalCode"
              :error-messages="$error.getValidationError(errors, 'postalCode')"
              @input="$error.clearValidationError(errors, 'postalCode')"
              class="required"
              dense
              outlined
            />
          </v-col>
        </v-row>

        <v-row class="pb-5">
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="py-0">
            <PhoneNumberField
              id="phoneNumber1"
              name="phoneNumber1"
              :label="$i18n.translate('Phone Number')"
              v-model="form.phoneNumber1"
              :error-messages="$error.getValidationError(errors, 'phoneNumber1')"
              @input="$error.clearValidationError(errors, 'phoneNumber1')"
              :rules="rules.phoneNumber"
              :country="form.address.country"
              class="required"
              dense
              outlined
            ></PhoneNumberField>
          </v-col>
        </v-row>
      </span>

      <h3 class="mb-5">Please enter your rider status and type</h3>
      <p class="pb-5">
        For "Rider Type" you may select more than one option.
      </p>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
          <CustomField
            dense
            v-if="selectedProgram?.participantCustomFields"
            v-model="form.customFieldValue7"
            :customField="selectedProgram.participantCustomFields[6]"
            class="required"
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
          <CustomField
            dense
            v-if="selectedProgram?.participantCustomFields"
            v-model="form.customFieldValue11"
            :customField="selectedProgram.participantCustomFields[10]"
            class="required"
            outlined
          />
        </v-col>
      </v-row>

      <h3 class="mt-4">
        Were you referred?
      </h3>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
          <CustomField
            :label="
              $i18n.translate('Were you referred to this program by an authorized Kawasaki motorcycle dealership?')
            "
            dense
            v-if="selectedProgram?.participantCustomFields"
            v-model="form.customFieldValue10"
            :customField="selectedProgram.participantCustomFields[9]"
            class="required"
            outlined
          />
        </v-col>
      </v-row>

      <span v-if="selectedParticipant && !selectedParticipant.user.tin">
        <h3 class="pt-5 pb-5">Please enter your SSN</h3>
        <p class="pb-5">
          Your SSN is used to issue a Kawasaki prepaid card that will be used for your earnings. If your earnings are
          high enough within a one year period, we may be required to report a 1099 form.
        </p>

        <v-row>
          <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="4" class="py-0">
            <TinField
              tooltip="We collect SSN for card issuance and 1099 reporting"
              dense
              outlined
              v-model="form.transientTin"
              :country="selectedParticipant.address.country"
              class="required"
              :rules="rules.tin"
              :isRegistered="true"
              clearable
            />
          </v-col>
        </v-row>
      </span>

      <h3 class="mt-5 mb-5">Please enter optional rider details</h3>
      <p class="pb-5">
        If you are entering responses for "Classes" and "Series", please separate your responses with a comma.
      </p>

      <v-row>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
          <CustomField
            dense
            v-if="selectedProgram?.participantCustomFields"
            v-model="form.customFieldValue12"
            :customField="selectedProgram.participantCustomFields[11]"
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
          <CustomField
            dense
            v-if="selectedProgram?.participantCustomFields"
            v-model="form.customFieldValue13"
            :customField="selectedProgram.participantCustomFields[12]"
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
          <CustomField
            dense
            v-if="selectedProgram?.participantCustomFields"
            v-model="form.customFieldValue14"
            :customField="selectedProgram.participantCustomFields[13]"
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0">
          <CustomField
            dense
            v-if="selectedProgram?.participantCustomFields"
            v-model="form.customFieldValue15"
            :customField="selectedProgram.participantCustomFields[14]"
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right">
          <v-btn :disabled="!valid" color="primary" @click="$emit('next')">
            Next <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import CustomField from "../../gapp-components/components/fields/CustomField.vue";
import PhoneNumberField from "../../gapp-components/components/fields/PhoneNumberField.vue";
import PostalCodeField from "../../gapp-components/components/fields/PostalCodeField.vue";
import RegionField from "../../gapp-components/components/fields/RegionField.vue";
import TinField from "../../gapp-components/components/fields/TinField.vue";

export default {
  components: { CustomField, TinField, RegionField, PostalCodeField, PhoneNumberField },
  props: {
    form: Object,
    rules: Object,
    errors: {},
    fromRegister: Boolean
  },
  data() {
    return {
      valid: false
    };
  },
  methods: {},
  computed: {
    ...mapGetters(["selectedProgram", "selectedParticipant"])
  }
};
</script>
